/* eslint-disable jsx-a11y/anchor-is-valid */

import Logo from "../Assets/touchlogo1.png";



const Navbar = () => {
  
  
  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" class="responsive"/>
      </div>
      
    
      
         
    </nav>
  );
};

export default Navbar;
