import "./App.css";
import Home from "./Components/Home";

import Contact from "./Components/Contact";


function App() {
  return (
    <div className="App">
      <Home />
     
      
      <Contact />
      
    </div>
  );
}

export default App;
