import React from "react";
import BannerImage from "../Assets/mobile.png";
import Navbar from "./Navbar";
import apple from "../Assets/apple.png"
import google from "../Assets/google.png"

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        
        <div className="home-text-section">
          <h1 className="primary-heading">
            A place to Socialize,Earn and Grow.
          </h1>
          <p className="primary-text">
          Social Netwrok, Ecommerce, Finance and Networking .
          </p>
          <p className="primary-text">
          click here to Download
          </p>
          <div className="playstore">
                 <a href="https://play.google.com/store/apps/details?id=com.touchhapp&pli=1" target="https://play.google.com/store/apps/details?id=com.touchhapp&pli=1" rel="noreferrer">
        <img src={google} alt="Google"></img>
      </a>
      <a href="https://apps.apple.com/in/app/touch-ui/id1630662736" target="https://apps.apple.com/in/app/touch-ui/id1630662736" rel="noreferrer">
        <img src={apple} alt="apple"></img>
      </a>
                 </div>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" class="responsive"/>
        </div>
      </div>
    </div>
  );
};

export default Home;
